<template>
    <header :class="['insight-header', `insight-header--${context}`]">
        <h3 v-if="context === 'student'" class="insight-header__title">
            <router-link
                :to="{
                    name: 'student_insight',
                    params: { id: studentId }
                }"
            >
                {{ studentName }}
            </router-link>
        </h3>

        <AppBadgeGroup>
            <AppBadge :variant="insightType">
                {{ insightTypeDisplay }}
            </AppBadge>

            <template v-if="!isExpired">
                <AppBadge
                    v-if="isEncouraging === true"
                    key="encouraging"
                    v-translate
                >
                    Bevorderend
                </AppBadge>
                <AppBadge
                    v-else-if="isEncouraging === false"
                    key="not-encouraging"
                    v-translate
                >
                    Belemmerend
                </AppBadge>
            </template>

            <AppBadge
                v-if="isExpired"
                v-translate
                variant="expired"
                icon="check-small"
            >
                {{
                    insightType === "education_plan" ? "Afgerond" : "Vervallen"
                }}
            </AppBadge>
        </AppBadgeGroup>

        <div
            v-if="insightType !== 'educational_level'"
            class="insight-header-icons"
        >
            <AppIcon
                v-if="!hideImportant"
                variant="disabled"
                :color="isImportant ? 'red' : ''"
                class="insight-header__icon"
                name="flag"
            />
            <AppButton
                v-if="
                    context === 'schedule' &&
                        (insightType !== 'diagnosis' || isAdmin)
                "
                variant="icon-clean"
                class="u-margin-left-small"
                icon="eye"
                icon-size="regular"
                @click="$emit('open-modal')"
            />
        </div>
    </header>
</template>

<script>
import AppBadge from "@/components/AppBadge";
import AppBadgeGroup from "@/components/AppBadgeGroup.vue";
import AppButton from "@/components/AppButton";
import AppIcon from "@/components/AppIcon";

export default {
    name: "AppInsightHeader",
    components: { AppBadgeGroup, AppIcon, AppButton, AppBadge },
    props: {
        context: {
            type: String,
            default: "",
            validator: value => {
                return [
                    "",
                    "schedule",
                    "student",
                    "timeline",
                    "group"
                ].includes(value);
            }
        },
        insightType: {
            type: String,
            default: ""
        },
        insightTypeDisplay: {
            type: String,
            default: ""
        },
        isAdmin: Boolean,
        isExpired: Boolean,
        isEncouraging: {
            type: [Boolean, String],
            default: null
        },
        isImportant: Boolean,
        hideImportant: Boolean,
        studentId: {
            type: String,
            default: ""
        },
        studentName: {
            type: String,
            default: ""
        }
    }
};
</script>
