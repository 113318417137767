<template>
    <AppInsightWrapper :context="context" :insight="insight">
        <div :class="['insight', `insight--${context}`]">
            <div
                v-if="isInsightExpiredCopy || isInsightEvaluation"
                class="insight__evaluation"
            >
                <AppHeader size="small">
                    <AppTitle as="h3" size="xsmall">
                        <!-- prettier-ignore -->
                        <translate v-if="isInsightEvaluation">Tussentijdse evaluatie</translate>
                        <translate v-else>Afrondende evaluatie</translate>
                    </AppTitle>
                </AppHeader>

                <AppArticle color="blue-shade-dark">
                    {{ insight.evaluation }}
                </AppArticle>
            </div>

            <div
                :class="[
                    'insight-inner',
                    {
                        'insight-inner--evaluation':
                            isInsightEvaluation || isInsightExpiredCopy
                    }
                ]"
            >
                <AppInsightHeader
                    :context="context"
                    :insight-type="insight.type"
                    :insight-type-display="insight.typeDisplay"
                    :is-admin="isAdmin"
                    :is-encouraging="insight.encouraging"
                    :is-expired="!!insight.expired"
                    :is-important="insight.important"
                    :hide-important="insight.type === 'absence'"
                    :student-id="insight.student ? insight.student.id : null"
                    :student-name="
                        insight.student ? insight.student.fullName : null
                    "
                    @open-modal="openInsightModal"
                />

                <AppInsightBody
                    :context="context"
                    :insight-attachment="insight.attachment"
                    :insight-educational-level-from="
                        insight.educationalLevelFrom
                    "
                    :insight-educational-level-subject="
                        insight.educationalLevelSubject
                    "
                    :insight-educational-level-to="insight.educationalLevelTo"
                    :is-finished="isInsightExpiredCopy"
                    :is-compact="isInsightEvaluation || isInsightExpiredCopy"
                    :insight-goal="insight.goal"
                    :insight-students="insight.students"
                    :insight-text="insight.insight"
                    :insight-title="insightTitle"
                    :insight-type="insight.type"
                />

                <AppInsightFooter
                    v-if="showFooter"
                    :context="context"
                    :date-weekdays-display="dateWeekdaysDisplay"
                    :insight-date-specific="insight.dateSpecific"
                    :insight-type="insight.type"
                    :is-admin="isAdmin"
                    :is-encouraging="insight.encouraging"
                    :subjects-display="subjectsDisplay"
                    @open-modal="openInsightModal"
                />
            </div>
        </div>

        <AppEditMultipleEducationPlanStudentsModal
            v-if="isBulkInsight"
            v-model="showMultiInsightModal"
            :bulk-id="insight.bulkId"
            :group-id="groupId"
            :is-expired="!!insight.expired"
        />

        <AppInsightDetailModal
            v-model="showDetailModal"
            :insight-id="insight.insightId"
        />
    </AppInsightWrapper>
</template>

<script>
import AppArticle from "@/components/AppArticle.vue";
import AppEditMultipleEducationPlanStudentsModal from "@/components/AppEditMultipleEducationPlanStudentsModal";
import AppHeader from "@/components/AppHeader.vue";
import AppInsightBody from "@/components/AppInsightBody";
import AppInsightDetailModal from "@/components/AppInsightDetailModal.vue";
import AppInsightFooter from "@/components/AppInsightFooter";
import AppInsightHeader from "@/components/AppInsightHeader";
import AppInsightWrapper from "@/components/AppInsightWrapper.vue";
import AppTitle from "@/components/AppTitle.vue";
import { mapGetters } from "vuex";

export default {
    name: "AppInsight",
    components: {
        AppArticle,
        AppTitle,
        AppHeader,
        AppInsightWrapper,
        AppInsightDetailModal,
        AppEditMultipleEducationPlanStudentsModal,
        AppInsightFooter,
        AppInsightBody,
        AppInsightHeader
    },
    props: {
        context: {
            type: String,
            default: "timeline"
        },
        groupId: {
            type: String,
            default: ""
        },
        insight: {
            type: Object,
            required: true
        },
        hideFooter: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showMultiInsightModal: false,
            showDetailModal: false
        };
    },
    computed: {
        ...mapGetters("auth", ["permissions"]),
        dateWeekdaysDisplay() {
            return this.insight.dateWeekdays.length > 0
                ? this.insight.dateWeekdays
                      .map(x =>
                          this.$moment()
                              .weekday(x)
                              .format("dddd")
                      )
                      .join(", ")
                : "";
        },
        isInsightExpiredCopy() {
            return (
                atob(this.insight.id).split(":")[0] === "InsightExpiredProxy"
            );
        },
        isInsightEvaluation() {
            return (
                atob(this.insight.id).split(":")[0] === "InsightEvaluationProxy"
            );
        },
        isAdmin() {
            return this.permissions === "admin";
        },
        subjectsDisplay() {
            if (
                this.insight.type === "education_plan" &&
                this.insight.educationPlanSubject
            ) {
                if (this.insight.educationPlanSubject.parent) {
                    return `${this.insight.educationPlanSubject.parent.name} | ${this.insight.educationPlanSubject.name}`;
                }

                return this.insight.educationPlanSubject.name;
            }

            return this.insight.subjects.length > 0
                ? this.insight.subjects.map(x => x.name).join(", ")
                : "";
        },
        isBulkInsight() {
            return this.insight && this.insight.bulkId && this.groupId;
        },
        showFooter() {
            if (this.hideFooter) {
                return false;
            } else if (this.context === "schedule" && !this.subjectsDisplay) {
                return false;
            }

            return true;
        },
        insightTitle() {
            if (this.insight.type === "absence") {
                return `${this.insight.absenceTypeDisplay} (${this.insight.absenceDurationDisplay})`;
            }

            return this.insight.title;
        }
    },
    methods: {
        openInsightModal() {
            if (
                this.isBulkInsight &&
                this.insight.students &&
                this.insight.students.length > 1
            ) {
                this.showMultiInsightModal = true;
            } else {
                this.showDetailModal = true;
            }
        }
    }
};
</script>

<style lang="sass">
.insight
    position: relative

    display: flex
    flex-direction: column
    flex-grow: 1
    min-height: 70px
    min-width: 0

    background-color: #fff
    box-shadow: 1px 1px 2px rgba(#000, .1)
    border-radius: 6px

.insight::before
    position: absolute
    left: 36px
    top: -6px

    width: 20px
    height: 20px
    margin-left: -10px

    background-color: #fff
    content: ''
    transform: rotate(45deg)

    +mq-tablet
        top: 27px
        left: 0

.insight--group,
.insight--student
    margin-left: 12px

.insight--compact
    .insight-header
        flex-grow: 1

.insight-header
    position: relative
    z-index: 1

    display: flex
    align-items: center
    padding: $spacing-regular $spacing-regular $spacing-small $spacing-regular

    +mq-lte-tablet-portrait
        flex-wrap: wrap

.insight-header__title
    margin-right: $spacing-regular

.insight-header-title--expired
    color: rgba($color-base-font, .29)
    text-decoration: line-through

.insight-header-icons
    margin-left: auto

    +mq-lte-tablet-portrait
        .insight-header__title ~ &
            position: absolute
            top: $spacing-regular
            right: $spacing-regular

.insight-body
    position: relative
    padding:
        top: 4px
        right: $spacing-regular
        bottom: $spacing-regular
        left: $spacing-regular


.insight-body__title
    margin-bottom: $spacing-small

    font-weight: $strong-weight

.insight-attachment
    margin-top: $spacing-small

.insight__text-expired
    margin-right: $spacing-regular

    font-weight: $semistrong-weight

.insight-footer
    position: relative

    display: flex
    align-items: center
    padding: 8px $spacing-regular

    @include font-size(14px, 1)

    background-color: $color-blue-lightest
    border-radius: 0 0 6px 6px

.insight-footer-info
    display: flex
    align-items: center
    margin-right: $spacing-regular

    font-weight: $semistrong-weight

.insight-inner--evaluation
    margin:
        right: $spacing-regular
        bottom: $spacing-regular
        left: $spacing-regular

    border: 2px solid $color-blue-lighter
    border-radius: 6px

.insight__evaluation
    padding: $spacing-regular $spacing-regular 16px $spacing-regular

/* Types */
/*---------------------------------------------------------------------------*/

// schedule

.insight--schedule
    box-shadow: none
    background-color: transparent

    &::after
        display: none

.insight-header--schedule
    padding: 0
    margin-bottom: $spacing-small

.insight-body--schedule
    padding: 0

.insight-header--schedule
    .insight-header__title
        @include font-size(16px)

    .insight-header__icon
        +mq-lte-tablet-portrait
            right: 0

.insight-body--schedule
    @include font-size(16px)

    border-top: 0

    .insight__text
        @include font-size(16px)

.insight-footer--schedule
    padding: $spacing-small
</style>
