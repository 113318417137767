var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['insight-body', ("insight-body--" + _vm.context)]},[(_vm.insightTitle)?_c('div',{staticClass:"insight-body__title"},[_vm._v(" "+_vm._s(_vm.insightTitle)+" ")]):_vm._e(),_c('AppArticle',{staticClass:"insight__text",attrs:{"color":"blue-shade-dark"}},[(_vm.insightType === 'education_plan' && _vm.insightGoal)?_c('div',{class:[
                'insight__goal',
                {
                    't-truncate': _vm.isCompact
                }
            ]},[(_vm.isFinished)?_c('strike',[_vm._v(_vm._s(_vm.insightGoal))]):[_vm._v(" "+_vm._s(_vm.insightGoal)+" ")]],2):(_vm.insightText)?_c('div',{class:{ 't-truncate': _vm.isCompact }},[(_vm.isFinished)?_c('strike',[_vm._v(_vm._s(_vm.insightText))]):[_vm._v(" "+_vm._s(_vm.insightText)+" ")]],2):_vm._e()]),(_vm.insightAttachment && _vm.insightAttachment.url)?_c('div',{staticClass:"insight-attachment u-margin-top-small"},[_c('AppLink',{staticClass:"link",attrs:{"as":"a","download":"","href":_vm.insightAttachment.url,"target":"_blank"}},[_c('AppIcon',{staticClass:"link__icon",attrs:{"name":"attachment"}}),_c('span',{staticClass:"t-decoration-underline"},[_vm._v(" "+_vm._s(_vm.insightAttachment.filename)+" ")])],1)],1):_vm._e(),(
            (_vm.context === 'schedule' || _vm.context === 'group') &&
                _vm.insightStudents
        )?_c('AppStudentLinks',{staticClass:"u-margin-top-small"},_vm._l((_vm.insightStudents),function(student){return _c('AppStudentLink',{key:student.id,attrs:{"student-name":student.fullName,"student-id":student.id}})}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }