<template>
    <div class="insight-wrapper">
        <div
            v-if="context !== 'schedule'"
            :class="`insight-circle insight-circle--${context}`"
        >
            <div v-if="context === 'timeline'" class="insight-circle-inner">
                <span class="insight-circle__day-name">{{
                    insightDate | moment("dd")
                }}</span>
                <span class="insight-circle__day">{{
                    insightDate | moment("D")
                }}</span>
                <span class="insight-circle__month">{{
                    insightDateMonth
                }}</span>
            </div>
            <div
                v-else-if="context === 'student' || context === 'group'"
                class="insight-circle-inner"
            >
                <!-- prettier-ignore -->
                <AppAvatar
                    :image="insightAvatar"
                    size="normal"
                />
            </div>
        </div>

        <slot />
    </div>
</template>

<script>
import AppAvatar from "@/components/AppAvatar.vue";

export default {
    name: "AppInsightWrapper",
    components: {
        AppAvatar
    },
    props: {
        context: {
            type: String,
            default: "timeline"
        },
        insight: {
            type: Object,
            required: true
        }
    },
    computed: {
        insightDate() {
            if (this.isInsightExpiredCopy) {
                return this.insight.expired;
            } else if (this.isInsightEvaluation) {
                return this.insight.evaluationDate;
            } else {
                return this.insight.date;
            }
        },
        insightDateMonth() {
            return this.$moment(this.insightDate)
                .format("MMM")
                .replace(/\.$/, "");
        },
        isInsightExpiredCopy() {
            return (
                atob(this.insight.id).split(":")[0] === "InsightExpiredProxy"
            );
        },
        isInsightEvaluation() {
            return (
                atob(this.insight.id).split(":")[0] === "InsightEvaluationProxy"
            );
        },
        insightAvatar() {
            if (
                this.context === "student" &&
                this.insight.student.profilePicture &&
                this.insight.student.profilePicture.url
            ) {
                return this.insight.student.profilePicture.url;
            } else if (
                this.context === "group" &&
                this.insight.students &&
                this.insight.students.length === 1 &&
                this.insight.student.profilePicture &&
                this.insight.student.profilePicture.url
            ) {
                return this.insight.student.profilePicture.url;
            }

            return undefined;
        }
    }
};
</script>

<style lang="sass">
.insight-wrapper
    margin-bottom: $spacing-small

    +mq-tablet
        display: flex
        width: 100%

.insight-circle
    display: flex
    align-items: center
    justify-content: center

    flex: 0 0 50px
    width: 50px
    height: 50px
    margin-right: $spacing-small

    @include font-size(10px, 1)
    font-weight: $strong-weight
    color: #fff
    text-transform: uppercase
    text-align: center

    border-radius: 50%

    +mq-lte-mobile
        margin-bottom: $spacing-regular

.insight-circle--timeline
    flex: 0 0 72px
    width: 72px
    height: 72px

    background-color: $color-blue

.insight-circle--group,
.insight-circle--student
    margin-top: 12px

.insight-circle__day,
.insight-circle__day-name,
.insight-circle__month
    display: block

.insight-circle__day
    @include font-size(27px, 1)
</style>
