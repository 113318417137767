<template>
    <div :class="['insight-body', `insight-body--${context}`]">
        <div v-if="insightTitle" class="insight-body__title">
            {{ insightTitle }}
        </div>

        <AppArticle class="insight__text" color="blue-shade-dark">
            <div
                v-if="insightType === 'education_plan' && insightGoal"
                :class="[
                    'insight__goal',
                    {
                        't-truncate': isCompact
                    }
                ]"
            >
                <strike v-if="isFinished">{{ insightGoal }}</strike>
                <template v-else>
                    {{ insightGoal }}
                </template>
            </div>
            <div v-else-if="insightText" :class="{ 't-truncate': isCompact }">
                <strike v-if="isFinished">{{ insightText }}</strike>
                <template v-else>
                    {{ insightText }}
                </template>
            </div>
        </AppArticle>

        <div
            v-if="insightAttachment && insightAttachment.url"
            class="insight-attachment u-margin-top-small"
        >
            <AppLink
                as="a"
                class="link"
                download
                :href="insightAttachment.url"
                target="_blank"
            >
                <AppIcon class="link__icon" name="attachment" />
                <span class="t-decoration-underline">
                    {{ insightAttachment.filename }}
                </span>
            </AppLink>
        </div>

        <AppStudentLinks
            v-if="
                (context === 'schedule' || context === 'group') &&
                    insightStudents
            "
            class="u-margin-top-small"
        >
            <AppStudentLink
                v-for="student in insightStudents"
                :key="student.id"
                :student-name="student.fullName"
                :student-id="student.id"
            />
        </AppStudentLinks>
    </div>
</template>

<script>
import AppArticle from "@/components/AppArticle.vue";
import AppIcon from "@/components/AppIcon";
import AppLink from "@/components/AppLink.vue";
import AppStudentLink from "@/components/AppStudentLink";
import AppStudentLinks from "@/components/AppStudentLinks";

export default {
    name: "AppInsightBody",
    components: {
        AppStudentLink,
        AppStudentLinks,
        AppIcon,
        AppArticle,
        AppLink
    },
    props: {
        insightAttachment: {
            type: Object,
            default: null
        },
        insightEducationalLevelFrom: {
            type: [String, Number],
            default: null
        },
        insightEducationalLevelSubject: {
            type: Object,
            default: null
        },
        insightEducationalLevelTo: {
            type: [String, Number],
            default: null
        },
        isFinished: Boolean,
        isCompact: Boolean,
        insightTitle: {
            type: String,
            default: ""
        },
        insightText: {
            type: String,
            default: ""
        },
        insightGoal: {
            type: String,
            default: ""
        },
        insightStudents: {
            type: Array,
            default: () => []
        },
        insightType: {
            type: String,
            default: ""
        },
        context: {
            type: String,
            default: "",
            validator: value => {
                return [
                    "",
                    "schedule",
                    "student",
                    "timeline",
                    "group"
                ].includes(value);
            }
        }
    }
};
</script>
