<template>
    <footer :class="['insight-footer', `insight-footer--${context}`]">
        <div class="insight-footer-info">
            <AppList variant="inline" gap="x-large y-xsmall">
                <AppListItem
                    v-if="insightDateSpecific && context !== 'schedule'"
                    variant="centered"
                >
                    <AppIcon class="u-margin-right-tiny" name="calendar" />
                    <span>{{ insightDateSpecific | moment("LL") }}</span>
                </AppListItem>
                <AppListItem
                    v-else-if="dateWeekdaysDisplay && context !== 'schedule'"
                    variant="centered"
                >
                    <AppIcon class="u-margin-right-tiny" name="calendar" />
                    <translate
                        :translate-params="{
                            weekdays: dateWeekdaysDisplay
                        }"
                    >
                        Elke %{weekdays}
                    </translate>
                </AppListItem>
                <AppListItem v-if="subjectsDisplay" variant="centered">
                    <AppIcon class="u-margin-right-tiny" name="subject" />
                    {{ subjectsDisplay }}
                </AppListItem>
            </AppList>
        </div>

        <div class="insight-footer-edit u-margin-left-auto">
            <AppButton
                v-if="
                    insightType !== 'educational_level' &&
                        context !== 'schedule'
                "
                variant="icon-clean"
                icon="eye"
                icon-size="regular"
                @click="$emit('open-modal')"
            />
        </div>
    </footer>
</template>

<script>
import AppButton from "@/components/AppButton";
import AppIcon from "@/components/AppIcon";
import AppList from "@/components/AppList";
import AppListItem from "@/components/AppListItem";

export default {
    name: "AppInsightFooter",
    components: {
        AppListItem,
        AppList,
        AppIcon,
        AppButton
    },
    props: {
        context: {
            type: String,
            default: "",
            validator: value => {
                return [
                    "",
                    "schedule",
                    "student",
                    "timeline",
                    "group"
                ].includes(value);
            }
        },
        dateWeekdaysDisplay: {
            type: String,
            default: ""
        },
        insightDateSpecific: {
            type: [Date, String],
            default: null
        },
        insightType: {
            type: String,
            default: ""
        },
        isAdmin: Boolean,
        subjectsDisplay: {
            type: String,
            default: ""
        }
    }
};
</script>
